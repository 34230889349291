<template>

  <b-card>
    <b-row>
      <b-col md="3">
        <b-card  
          no-body
          class="card-developer-meetup"
        >
          <b-card-body>
            <!-- metting header -->
            <div class="meetup-header d-flex align-items-center">
              <div class="meetup-day" style="padding-right: 1.0rem; margin-right: 1.0rem;" v-if="get_device.name != undefined">
               <b-badge :variant="possible_status.find(find_data => find_data.id == get_device.status).variant">
                  <h4 class="mb-0" style="color:white; padding: 0.3rem">
                  {{ possible_status[get_device.status].name }}
                </h4>

               </b-badge>
              </div>
              <div class="my-auto">
                <b-card-title class="mb-0">
                  {{ get_device.name }}
                </b-card-title>
                <b-card-text class="font-small"
                v-b-tooltip.hover.v-secondary.left
                title="ID do dispositivo"
                variant="outline-secondary">
                  #{{ get_device.id }}
                </b-card-text>
        
              </div>
            </div>

            <b-media              
              no-body
            >
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-dark"
                  size="34"
                >
                  <feather-icon
                    icon="UsersIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  Grupo
                </h6>
                <small> {{ in_group }} </small>
              </b-media-body>
            </b-media>

            <b-media              
              no-body
            >
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-dark"
                  size="34"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  Criador
                </h6>
                <small> {{ by_user }} </small>
              </b-media-body>
            </b-media>

          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="6" style="margin-top:0.75rem">
        <small> CONFIGURAÇÃO DO FIRMWARE </small>
        <prism
        style="margin-top:0.25rem !important"
        language="javascript"
        class="rounded mt-2"
      > {{ code }}
      
      </prism>
      <small style="font-size: .9rem"> É necessário utilizar o token fornecido pelo dispositivo </small>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardText, VBTooltip, BFormDatepicker, BRow, BCol, BFormGroup, BCardTitle, BCardBody, BMediaAside, BMedia, BMediaBody, BAvatar, BAvatarGroup, BButton, BBadge, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Cookies from 'js-cookie'
import store from '@/store/index'
import axios from "axios";
import api from '@/service/api'

export default {
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BFormDatepicker,
    BMedia,
    BMediaAside,
    BRow,
    BCol,
    vSelect,
    BFormGroup,
    BMediaBody,
    BAvatar,
    BAvatarGroup,
    BButton,
    BBadge,
    Prism,
    
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created(){
    let self = this;
    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }

    if(Cookies.get('token_api') == undefined){
      top.location.href = "/login";
    }
    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }
    
    self.Get_device();

  },
  data() {
    return {
      by_user: '',
      in_group: '',
      get_group: [],
      get_device: [],
      code:
      "",
      
      possible_status:[
        {
          id: 0,
          name: 'OFF',
          variant: 'danger',
        },
        {
          id: 1,
          name: 'ON',
          variant: 'success',
        }
      ],

    }
  },
  methods: {
    Get_device(){
      var self = this;
      let group_request = '?idGroup=' + store.state.active_group.id
      let selected_device = '&idDevice='+ store.state.selected_device;

      var config = {
        method: 'get',
        url: 'https://tekie.cloud:3031/devices' + group_request + selected_device,
        headers: { 
          'Authorization': store.state.token_api
        }
      };

      axios(config)
      .then(function (response) {
        if(response.data.error == undefined){
          self.get_device = response.data.device.data[0];
          let token = response.data.device.data[0].token;
          if ( self.get_device.deviceType == 0){
            self.code = "url = 'http://tekie.work:3031/datas' \n  value = Valor \n  variableId = 'Id_Variável' \n  valueTime = 'timestamp' \n  token = '" + token + "'"
          }
          else if(self.get_device.deviceType == 1){
            self.code = "url = '207.244.240.133:3999'\n  valor = '" + self.get_device.idTokenDevice + ",latitude,longitude'"
          }
        }

      })
      .catch(function (error) {
        //error);
        //"Deu erro");
      });
      
      var get_groups = {
        method: 'get',
        url: 'https://tekie.cloud:3031/group',
        headers: { 
          'Authorization': store.state.token_api
        }
      };
      var self = this;
      axios(get_groups)
      .then(function (response) {
        self.get_group = response.data.data
        for( let i = 0; i < self.get_group.length; i++){
          if(self.get_group[i].id == self.get_device.groupId){
            self.in_group = self.get_group[i].name;
          }
        }
      
      })
      .catch(function (error) {
        //error);
      });
      var get_members = {
        method: 'get',
        url: 'https://tekie.cloud:3031/permissions?idGroup=' + store.state.active_group.id,
        headers: { 
          'Authorization': store.state.token_api
        }
      };

      axios(get_members)
      .then(function (response) {
        for( let i = 0; i < response.data.dadosMembersGroup.length; i++){
          if(response.data.dadosMembersGroup[i].id == self.get_device.userId){
            self.by_user = response.data.dadosMembersGroup[i].nome;
          }
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
